.plan {
    background-color: #EEF4F0;
    height: calc(100vh - 80px);
    width: 100vw;
    overflow: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.plan::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.card-box {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 40px;
    padding: 20px;
    height: 60vh;
    width: 245px;
    margin: 10px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 2px rgb(200, 200, 200);
}

#plan-btn {
    background: #008060;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 150px;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
    position: relative;
    left: 12%;
}

@media (max-width: 375px) {
    .card {
        width: 200px;
    }
    #plan-btn {
        width: 100px;
        left: 15%;
        font-size: 15px;
    }

    .card-box {
        font-size: 15px;
    }
}