.home {
    width: 100vw;
    height: 100vh;
    background: #EEF4F0;
}

.form {    
    position: relative;
    top: 25vh;
    background: #FFFFFF;
    padding: 20px;
    margin: 0px auto 0 auto;
    width: 50vw;
    box-shadow: 0px 0px 5px rgba(58, 55, 55, 0.211);
    border-radius: 10px;
}

input[type=text] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button {
    width: 150px;
    background-color: #1a1a1a;
    color: white;
    padding: 10px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
}