@import url('https://fonts.googleapis.com/css2?family=Inter&family=Kanit:wght@200;400&family=Roboto&family=Rubik+Maps&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

html,
body {
    width: 100%;
    height: 100%;
    /* font-size: 15px !important;
    letter-spacing: normal;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif !important;
    font-weight: 500; */
}