.dashboard {
    background-color: #F7F7F7;
    height: calc(100vh - 80px);
    width: 100vw;
    overflow: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.dashboard::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.info-block {   
    display: flex;
    flex-wrap: wrap;
}

.box1 {
    background-color: rgb(238, 244, 240);
    width: auto;
    color: black;
    border-radius: 5px;
    margin: 10px 10px 10px 10px;
    padding: 10px;
    box-shadow: 0px 0px 2px #24412a;
}

.mini-text0 {
    background: black;
    color: white;
    padding: 10px;  
    border-radius: 5px;
    margin: 10px 10px;
    box-shadow: 0px 0px 2px rgb(200, 200, 200);
}

.mini-text1 {
    background: white;
    color: black;
    padding: 10px;  
    border-radius: 5px;
    margin: 10px 10px;
    box-shadow: 0px 0px 2px rgb(200, 200, 200);
}

@media (max-width: 1050px) {

    .info-block {
        justify-content: center;
    }
}

