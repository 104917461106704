.help {
    background: #EBEBEB;
    height: calc(100vh - 80px);
    width: 100vw;
    overflow: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.help::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.help-box {
    margin: 15px;
    padding: 15px;
    background-color: #F7F7F7;
    border-radius: 10px;
}

.contact-box {
    background-color: black;
    color: white;
    margin: 10px 20px;
    padding: 20px;
    border-radius: 10px;
    line-height: 30px;
    box-shadow: 0px 0px 2px #000000
}

.editor-box {
    background-color: white;
    margin: 10px 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px grey;
}

.editor-box textarea {
    height: 100px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    border: none;
    box-shadow: 0px 0px 2px grey;
}

textarea:hover, textarea:focus {
    border: 1px solid rgb(33, 177, 249);
    outline: none;
}

#msg-btn {
    width: 170px;
    font-size: 18px;
    color: white;
    background-color: #008060;
    border:none;
    border-radius: 5px;
    position: relative;
    margin-top: 10px;
}

@media (max-width : 768px) {
    .help-box {
        margin: 5px;
        padding: 5px;
    }

    .contact-box {
        background-color: black;
        color: white;
        margin: 5px 10px;
        padding: 20px;
        border-radius: 10px;
        line-height: 30px;
    }

    #msg-btn {
        width: 120px;
        font-size: 15px;
    }

    #book-meeting {
        width: 130px;
        font-size: 15px;
    }
}

@media (max-width : 375px) {
    .contact-box {
        font-size: small;
    }
}