.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100vw;
    background-color: black;
    color: white;
}

.logo {
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding: 12px;
    border-radius: 0 5px 5px 0;
}

.logo>img {
    height: 50px;
}

.navbar {
    width: 240px;
    height: calc(100vh - 80px);
}

.mini-nav {
    display: none;
    width: 100px;
    height: calc(100vh - 80px);
}

.content {
    display: flex;
}

li {
    padding: 15px;
    list-style-type: none;
    font-size: 18px;
    margin-left: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease-out;
}


a {
    text-decoration: none;
    color: #4a4a4a;
}

li:hover {
    background-color: #4a4a4a;
    border-radius: 5px 0 0 5px;
    color: white;
}

.abc {
    background-color: #4a4a4a;
    border-radius: 5px 0 0 5px;
    color: white;
}

i {
    margin-right: 15px;
}

.plan-btn {
    padding: 10px;
    width: 100px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: #008060;
    font-size: 17px;
    margin-right: 20px;
}

@media (max-width: 1050px) {
    .navbar {
        width: 210px;
    }

    .navbar>li {
        margin-left: 15px;
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .navbar {
        display: none;
    }

    .mini-nav {
        display: block;
    }
}

@media (max-width : 415px) {
    .navbar {
        display: none;
    }

    .mini-nav {
        width: 80px;
    }
}

@media (max-width : 375px) {
    .plan-btn {
        display: none;
    }
}